export const paths = {
  index: "/",
  home: {
    product: '/products',
    aboutUs: '/about_us',
    contactUs: '/contact_us',
    orderNow: '/order_now',
    customerCare: '/customer_care',
    privatePolicy: '/private_policy',
    cookiePolicy: '/cookie_policy',
    logs: '/logs',
    wallet: '/wallets',
    customers: '/customers',
    agents: '/agents',
    internalChat: '/internal-chat',
    lead: {
      analytics: '/lead/analytics',
      leads: '/lead/leads',
      affiliates: '/lead/affiliates',
      brands: '/lead/brands',
      listInjection: '/lead/list-injection',
      offers: '/lead/offers',
    },
    risk: {
      positions: '/risk/positions',
      transactions: '/risk/transactions',
      walletTransactions: '/risk/wallet-transactions',
    },
    payment: {
      merchant: '/payment-audit/merchant',
      bank: '/payment-audit/bank',
      paymentType: '/payment-audit/payment-type',
      validationType: '/payment-audit/validation-type',
      dataEntry: '/payment-audit/data-entry',
      records: '/payment-audit/records',
    },
    articles: '/articles',
    reports: '/reports',
  },
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  auth: {
    jwt: {
      login: "/auth/login",
      register: "/auth/register",
      companies: "/auth/companies",
      forgotPassword: "/auth/forgot-password",
      passwordRecovery: "/auth/password-recovery",
    },
  },
  authDemo: {
    forgotPassword: {
      classic: "/auth-demo/forgot-password/classic",
      modern: "/auth-demo/forgot-password/modern",
    },
    login: {
      classic: "/auth-demo/login/classic",
      modern: "/auth-demo/login/modern",
    },
    register: {
      classic: "/auth-demo/register/classic",
      modern: "/auth-demo/register/modern",
    },
    resetPassword: {
      classic: "/auth-demo/reset-password/classic",
      modern: "/auth-demo/reset-password/modern",
    },
    verifyCode: {
      classic: "/auth-demo/verify-code/classic",
      modern: "/auth-demo/verify-code/modern",
    },
  },
  dashboard: {
    index: "/dashboard",
    reports: "/dashboard/reports",
    leaderboard: "/dashboard/leaderboard",
    academy: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/courses/:courseId",
    },
    settings: "/dashboard/settings",
    account: "/dashboard/account",
    agents: "/dashboard/agents",
    log: {
      index: "/dashboard/logs",
    },
    risk: {
      index: "/dashboard/risk-management",
      positions: "/dashboard/risk-management/positions",
      transactions: "/dashboard/risk-management/transactions",
      wallet: '/dashboard/risk-management/wallet-transactions',
    },
    riskUpdate: "/dashboard/risk-management/positions/:riskId",
    riskCreate: "/dashboard/risk-management/positions/create",
    analytics: "/dashboard/analytics",
    article: {
      index: "/dashboard/article",
      articleDetails: "/dashboard/article/:articleId",
      articleEdit: "/dashboard/article/:articleId/edit",
      articleCreate: "/dashboard/article/create",
    },
    blank: "/dashboard/blank",
    blog: {
      index: "/dashboard/blog",
      postDetails: "/dashboard/blog/:postId",
      postCreate: "/dashboard/blog/create",
    },
    members: {
      access: "/dashboard/settings/:memberId/access",
    },
    calendar: "/dashboard/calendar",
    chat: "/dashboard/chat",
    internalChat: "/dashboard/internal-chat",
    crypto: "/dashboard/crypto",
    customers: {
      index: "/dashboard/customers",
      create: "/dashboard/customers/create",
      details: "/dashboard/customers/:customerId",
      edit: "/dashboard/customers/:customerId/edit",
    },
    supportChats: "/dashboard/support-chats",
    emails: "/dashboard/emails",
    ecommerce: "/dashboard/ecommerce",
    fileManager: "/dashboard/file-manager",
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
    jobs: {
      index: "/dashboard/jobs",
      create: "/dashboard/jobs/create",
      companies: {
        details: "/dashboard/jobs/companies/:companyId",
      },
    },
    kanban: "/dashboard/kanban",
    logistics: {
      index: "/dashboard/logistics",
      fleet: "/dashboard/logistics/fleet",
    },
    lead: {
      analytic: "/dashboard/lead/analytics",
      status: {
        index: "/dashboard/lead/status",
        create: `/dashboard/lead/status/create`,
        detail: `/dashboard/lead/status/:leadId`,
      },
      affiliate: {
        index: "/dashboard/lead/affiliate",
        create: `/dashboard/lead/affiliate/create`,
      },
      brands: {
        index: "/dashboard/lead/brands",
        create: `/dashboard/lead/brands/create`,
      },
      injection: {
        index: "/dashboard/lead/injection",
        create: `/dashboard/lead/injection/create`,
      },
      offers: {
        index: "/dashboard/lead/offers",
        create: "/dashboard/lead/offers/create",
        edit: "/dashboard/lead/offers/:offerId",
      },
    },
    integration: {
      index: "/dashboard/integration",
      callSystem: {
        index: "/dashboard/integration/call-system",
      },
      paymentService: {
        index: "/dashboard/integration/payment-service-providers",
      },
      marketingAgencies: {
        index: "/dashboard/integration/marketing-agencies",
      },
      lawFirms: {
        index: "/dashboard/integration/law-firms",
      },
      callProviderSettings: "/dashboard/integration/provider",
    },
    paymentAudit: {
      index: "/dashboard/payment-audit",
      merchant: {
        index: "/dashboard/payment-audit/merchant",
        create: "/dashboard/payment-audit/merchant/create",
        details: "/dashboard/payment-audit/merchant/:merchantId",
        edit: "/dashboard/payment-audit/merchant/:merchantId/edit",
      },
      bankProvider: {
        index: "/dashboard/payment-audit/bank-providers",
        details: "/dashboard/payment-audit/bank-providers/providerId",
        create: "/dashboard/payment-audit/bank-providers/create",
        edit: "/dashboard/payment-audit/bank-providers/:providerId/edit",
      },
      paymentType: {
        index: "/dashboard/payment-audit/payment-type",
        details: "/dashboard/payment-audit/payment-type/paymentTypeId",
      },
      validationRules: {
        index: "/dashboard/payment-audit/validation-rules",
        details: "/dashboard/payment-audit/validation-rules/ruleId",
        edit: "/dashboard/payment-audit/validation-rules/ruleId/edit",
      },
      dataEntry: {
        index: "/dashboard/payment-audit/data-entry",
        create: "/dashboard/payment-audit/data-entry/create",
        details: "/dashboard/payment-audit/data-entry/entryId",
      },
      record: {
        index: "/dashboard/payment-audit/record",
        create: "/dashboard/payment-audit/record/create",
        details: "/dashboard/payment-audit/record/alertId",
      },
    },
    mail: "/dashboard/mail",
    orders: {
      index: "/dashboard/orders",
      details: "/dashboard/orders/:orderId",
    },
    products: {
      index: "/dashboard/products",
      create: "/dashboard/products/create",
    },
    social: {
      index: "/dashboard/social",
      profile: "/dashboard/social/profile",
      feed: "/dashboard/social/feed",
    },
    wallets: {
      index: "/dashboard/wallets",
      detail: "/dashboard/wallets/:walletId/detail",
    },
    roles: {
      create: "/dashboard/roles/create",
      edit: "/dashboard/roles/:roleId/edit",
    },
  },
  components: {
    index: "/components",
    dataDisplay: {
      detailLists: "/components/data-display/detail-lists",
      tables: "/components/data-display/tables",
      quickStats: "/components/data-display/quick-stats",
    },
    lists: {
      groupedLists: "/components/lists/grouped-lists",
      gridLists: "/components/lists/grid-lists",
    },
    forms: "/components/forms",
    modals: "/components/modals",
    charts: "/components/charts",
    buttons: "/components/buttons",
    typography: "/components/typography",
    colors: "/components/colors",
    inputs: "/components/inputs",
  },
  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
};
