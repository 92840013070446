import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "src/layouts/dashboard";
import InjectionDetailPage from "src/pages/dashboard/lead-management/injection/detail";

// Index
const IndexPage = lazy(() => import("src/pages/dashboard/index"));

// Academy
const AcademyDashboardPage = lazy(() =>
  import("src/pages/dashboard/academy/dashboard")
);
const AcademyCoursePage = lazy(() =>
  import("src/pages/dashboard/academy/course")
);

const ReportsPage = lazy(() =>
  import("src/pages/reports")
)

// Blog
const BlogPostListPage = lazy(() => import("src/pages/dashboard/blog/list"));
const BlogPostDetailPage = lazy(() =>
  import("src/pages/dashboard/blog/detail")
);
const BlogPostCreatePage = lazy(() =>
  import("src/pages/dashboard/blog/create")
);
const BlogPostEditPage = lazy(() => import("src/pages/dashboard/blog/edit"));

// Customer
const CustomerListPage = lazy(() =>
  import("src/pages/dashboard/customers/list")
);
const CustomerDetailPage = lazy(() =>
  import("src/pages/dashboard/customers/detail")
);
const CustomerEditPage = lazy(() =>
  import("src/pages/dashboard/customers/edit")
);
const CustomerCreatePage = lazy(() =>
  import("src/pages/dashboard/customers/create")
);

// Job
const JobBrowsePage = lazy(() => import("src/pages/dashboard/jobs/browse"));
const JobCreatePage = lazy(() => import("src/pages/dashboard/jobs/create"));
const CompanyDetailPage = lazy(() =>
  import("src/pages/dashboard/jobs/companies/detail")
);

// Logistics
const LogisticsDashboardPage = lazy(() =>
  import("src/pages/dashboard/logistics/dashboard")
);
const LogisticsFleetPage = lazy(() =>
  import("src/pages/dashboard/logistics/fleet")
);

// Order
const OrderListPage = lazy(() => import("src/pages/dashboard/orders/list"));
const OrderDetailPage = lazy(() => import("src/pages/dashboard/orders/detail"));

// Product
const ProductListPage = lazy(() => import("src/pages/dashboard/products/list"));
const ProductCreatePage = lazy(() =>
  import("src/pages/dashboard/products/create")
);

// Social
const SocialFeedPage = lazy(() => import("src/pages/dashboard/social/feed"));
const SocialProfilePage = lazy(() =>
  import("src/pages/dashboard/social/profile")
);

// Other
const AccountPage = lazy(() => import("src/pages/dashboard/account"));
const AnalyticsPage = lazy(() => import("src/pages/dashboard/analytics"));
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));
const CalendarPage = lazy(() => import("src/pages/dashboard/calendar"));
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const InternalChatPage = lazy(() =>
  import("src/pages/dashboard/internal-chat")
);
const SupportChatsPage = lazy(() => import("src/pages/dashboard/support-chats"));
const EmailsPage = lazy(() => import("src/pages/dashboard/emails"));
const CryptoPage = lazy(() => import("src/pages/dashboard/crypto"));
const EcommercePage = lazy(() => import("src/pages/dashboard/ecommerce"));
const FileManagerPage = lazy(() => import("src/pages/dashboard/file-manager"));
const KanbanPage = lazy(() => import("src/pages/dashboard/kanban"));
const MailPage = lazy(() => import("src/pages/dashboard/mail"));

// Settings
const SettingsPage = lazy(() =>
  import("src/pages/dashboard/settings/settings")
);
const CallProviderPage = lazy(() =>
  import("src/pages/dashboard/integration/provider")
);
const SettingAccess = lazy(() => import("src/pages/dashboard/settings/access"));

//Agents
const AgentsPage = lazy(() => import("src/pages/dashboard/agents"));

const RiskEditPage = lazy(() => import("src/pages/dashboard/risk-edit"));
const RiskCreatePage = lazy(() => import("src/pages/dashboard/risk-create"));

//Risk Management
const Positions = lazy(() =>
  import("src/pages/dashboard/risk-management/positions")
);
const Transactions = lazy(() =>
  import("src/pages/dashboard/risk-management/transactions")
);
const WalletTransactions = lazy(() =>
  import("src/pages/dashboard/risk-management/wallet-transactions")
);

//Lead Management
const LeadAnalytics = lazy(() =>
  import("src/pages/dashboard/lead-management/analytics")
);

// Leaderboard
const Leaderboard = lazy(() =>
  import("src/pages/dashboard/leaderboard/index")
);

const LeadStatus = lazy(() =>
  import("src/pages/dashboard/lead-management/status/status")
);
const LeadStatusCreatePage = lazy(() =>
  import("src/pages/dashboard/lead-management/status/status-create")
);
const LeadStatusDetailPage = lazy(() =>
  import("src/pages/dashboard/lead-management/status/detail")
);

const Affiliate = lazy(() =>
  import("src/pages/dashboard/lead-management/affiliate/affiliate")
);
const AffiliateCreatePage = lazy(() =>
  import("src/pages/dashboard/lead-management/affiliate/affiliate-create")
);
const AffiliateDetailPage = lazy(() =>
  import("src/pages/dashboard/lead-management/affiliate/detail")
);

const Brands = lazy(() =>
  import("src/pages/dashboard/lead-management/brands/brands")
);
const BrandsCreatePage = lazy(() =>
  import("src/pages/dashboard/lead-management/brands/brands-create")
);
const BrandsDetailPage = lazy(() =>
  import("src/pages/dashboard/lead-management/brands/detail")
);

const Injection = lazy(() =>
  import("src/pages/dashboard/lead-management/injection/injection")
);
const InjectionCreatePage = lazy(() =>
  import("src/pages/dashboard/lead-management/injection/injection-create")
);

const Offers = lazy(() =>
  import("src/pages/dashboard/lead-management/offers/offers")
);
const OfferEdit = lazy(() =>
  import("src/pages/dashboard/lead-management/offers/offer-update")
);
const OfferCreate = lazy(() =>
  import("src/pages/dashboard/lead-management/offers/offer-create")
);

//Integration
const CallSystem = lazy(() =>
  import("src/pages/dashboard/integration/call-system/call-system")
);
const PaymentServices = lazy(() =>
  import(
    "src/pages/dashboard/integration/payment-service-providers/payment-service-providers"
  )
);
const MarketingAgencies = lazy(() =>
  import(
    "src/pages/dashboard/integration/marketing-agencies/marketing-agencies"
  )
);
const LawFirms = lazy(() =>
  import("src/pages/dashboard/integration/law-firms/law-firms")
);

//Payment Audit
const Merchant = lazy(() =>
  import("src/pages/dashboard/payment-audit/merchant")
);
const MerchantFeeCreate = lazy(() =>
  import("src/pages/dashboard/payment-audit/merchant/create")
);
const MerchantDetails = lazy(() =>
  import("src/pages/dashboard/payment-audit/merchant/detail")
);
const MerchantEditPage = lazy(() =>
  import("src/pages/dashboard/payment-audit/merchant/edit")
);

const BankProvider = lazy(() =>
  import("src/pages/dashboard/payment-audit/bank-provider")
);
const BankProviderDetails = lazy(() =>
  import("src/pages/dashboard/payment-audit/bank-provider/detail")
);
const BankProviderFeeCreate = lazy(() =>
  import("src/pages/dashboard/payment-audit/bank-provider/create")
);
const BankProviderEditPage = lazy(() =>
  import("src/pages/dashboard/payment-audit/bank-provider/edit")
);

const PaymentTypes = lazy(() =>
  import("src/pages/dashboard/payment-audit/payment-types")
);
const PaymentDetails = lazy(() =>
  import("src/pages/dashboard/payment-audit/payment-types/detail")
);

const ValidationRules = lazy(() =>
  import("src/pages/dashboard/payment-audit/validation-rules")
);
const ValidationRuleCreate = lazy(() =>
  import("src/pages/dashboard/payment-audit/validation-rules/create")
);
const ValidationRulesDetails = lazy(() =>
  import("src/pages/dashboard/payment-audit/validation-rules/detail")
);
const ValidationEditPage = lazy(() =>
  import("src/pages/dashboard/payment-audit/validation-rules/edit")
);

const DataEntry = lazy(() =>
  import("src/pages/dashboard/payment-audit/data-entry")
);
const DataEntryCreate = lazy(() =>
  import("src/pages/dashboard/payment-audit/data-entry/create")
);
const DataEntryDetailsPage = lazy(() =>
  import("src/pages/dashboard/payment-audit/data-entry/detail")
);

const Alert = lazy(() => import("src/pages/dashboard/payment-audit/record"));
const RecordCreate = lazy(() =>
  import("src/pages/dashboard/payment-audit/record/create")
);
const AlertDetailsPage = lazy(() =>
  import("src/pages/dashboard/payment-audit/record/detail")
);

const WalletsListPage = lazy(() => import("src/pages/dashboard/wallets/list"));
const WalletsDetailPage = lazy(() =>
  import("src/pages/dashboard/wallets/detail")
);

const RoleEditPage = lazy(() => import("src/pages/dashboard/role-edit"));
const RoleCreatePage = lazy(() => import("src/pages/dashboard/role-create"));

// LOGS
const LogsList = lazy(() => import("src/pages/dashboard/log/list"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "academy",
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />,
          },
          {
            path: "courses",
            children: [
              {
                path: ":courseId",
                element: <AcademyCoursePage />,
              },
            ],
          },
        ],
      },
      {
        path: "article",
        children: [
          {
            index: true,
            element: <BlogPostListPage />,
          },
          {
            path: "create",
            element: <BlogPostCreatePage />,
          },
          {
            path: ":articleId",
            element: <BlogPostDetailPage />,
          },
          {
            path: ":articleId/edit",
            element: <BlogPostEditPage />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: "create",
            element: <CustomerCreatePage />,
          },
          {
            path: ":customerId",
            element: <CustomerDetailPage />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEditPage />,
          },
        ],
      },
      {
        path: "logs",
        children: [
          {
            index: true,
            element: <LogsList />,
          },
        ],
      },
      {
        path: "jobs",
        children: [
          {
            index: true,
            element: <JobBrowsePage />,
          },
          {
            path: "create",
            element: <JobCreatePage />,
          },
          {
            path: "companies",
            children: [
              {
                path: ":companyId",
                element: <CompanyDetailPage />,
              },
            ],
          },
        ],
      },
      {
        path: "logistics",
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />,
          },
          {
            path: "fleet",
            element: <LogisticsFleetPage />,
          },
        ],
      },
      {
        path: "leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "lead",
        children: [
          {
            path: "analytics",
            element: <LeadAnalytics />,
          },
          {
            path: "status",
            children: [
              {
                index: true,
                element: <LeadStatus />,
              },
              {
                path: "create",
                element: <LeadStatusCreatePage />,
              },
              {
                path: ":leadId",
                element: <LeadStatusDetailPage />,
              },
            ],
          },
          {
            path: "affiliate",
            children: [
              {
                index: true,
                element: <Affiliate />,
              },
              {
                path: "create",
                element: <AffiliateCreatePage />,
              },
              {
                path: ":affiliateId",
                element: <AffiliateDetailPage />,
              },
            ],
          },
          {
            path: "brands",
            children: [
              {
                index: true,
                element: <Brands />,
              },
              {
                path: "create",
                element: <BrandsCreatePage />,
              },
              {
                path: ":brandId",
                element: <BrandsDetailPage />,
              },
            ],
          },
          {
            path: "injection",
            children: [
              {
                index: true,
                element: <Injection />,
              },
              {
                path: "create",
                element: <InjectionCreatePage />,
              },
              {
                path: ":injectionId",
                element: <InjectionDetailPage />,
              },
            ],
          },
          {
            path: "offers",
            children: [
              {
                index: true,
                element: <Offers />,
              },
              {
                path: ":offerId",
                element: <OfferEdit />,
              },
              {
                path: "create",
                element: <OfferCreate />,
              },
            ],
          },
        ],
      },
      {
        path: "integration",
        children: [
          {
            path: "call-system",
            children: [
              {
                index: true,
                element: <CallSystem />,
              },
            ],
          },
          {
            path: "payment-service-providers",
            children: [
              {
                index: true,
                element: <PaymentServices />,
              },
            ],
          },
          {
            path: "marketing-agencies",
            children: [
              {
                index: true,
                element: <MarketingAgencies />,
              },
            ],
          },
          {
            path: "law-firms",
            children: [
              {
                index: true,
                element: <LawFirms />,
              },
            ],
          },
          {
            path: "provider/:providerId",
            element: <CallProviderPage />,
          },
        ],
      },
      {
        path: "payment-audit",
        children: [
          {
            path: "merchant",
            children: [
              {
                index: true,
                element: <Merchant />,
              },
              {
                path: "create",
                element: <MerchantFeeCreate />,
              },
              {
                path: ":merchantId",
                element: <MerchantDetails />,
              },
              {
                path: ":merchantId/edit/:rateId",
                element: <MerchantEditPage />,
              },
            ],
          },
          {
            path: "bank-providers",
            children: [
              {
                index: true,
                element: <BankProvider />,
              },
              {
                path: ":providerId",
                element: <BankProviderDetails />,
              },
              {
                path: "create",
                element: <BankProviderFeeCreate />,
              },
              {
                path: ":providerId/edit/:rateId",
                element: <BankProviderEditPage />,
              },
            ],
          },
          {
            path: "payment-type",
            children: [
              {
                index: true,
                element: <PaymentTypes />,
              },
              {
                path: ":paymentTypeId",
                element: <PaymentDetails />,
              },
            ],
          },
          {
            path: "validation-rules",
            children: [
              {
                index: true,
                element: <ValidationRules />,
              },
              {
                path: ":taskId/create",
                element: <ValidationRuleCreate />,
              },
              {
                path: ":taskId",
                element: <ValidationRulesDetails />,
              },
              {
                path: ":taskId/edit/:ruleId",
                element: <ValidationEditPage />,
              },
            ],
          },
          {
            path: "data-entry",
            children: [
              {
                index: true,
                element: <DataEntry />,
              },
              {
                path: "create",
                element: <DataEntryCreate />,
              },
              {
                path: ":entryId",
                element: <DataEntryDetailsPage />,
              },
            ],
          },
          {
            path: "record",
            children: [
              {
                index: true,
                element: <Alert />,
              },
              {
                path: "create",
                element: <RecordCreate />,
              },
              {
                path: ":recordId",
                element: <AlertDetailsPage />,
              },
            ],
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            index: true,
            element: <OrderListPage />,
          },
          {
            path: ":orderId",
            element: <OrderDetailPage />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: <ProductListPage />,
          },
          {
            path: "create",
            element: <ProductCreatePage />,
          },
        ],
      },
      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeedPage />,
          },
          {
            path: "profile",
            element: <SocialProfilePage />,
          },
        ],
      },
      {
        path: "wallets",
        children: [
          {
            index: true,
            element: <WalletsListPage />,
          },
          {
            path: ":walletId",
            element: <WalletsDetailPage />,
          },
        ],
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "settings",
        children: [
          {
            index: true,
            element: <SettingsPage />,
          },
          {
            path: ":memberId/access",
            element: <SettingAccess />,
          },
        ],
      },
      {
        path: "roles/:roleId/edit",
        element: <RoleEditPage />,
      },
      {
        path: "roles/create",
        element: <RoleCreatePage />,
      },
      {
        path: "agents",
        element: <AgentsPage />,
      },
      {
        path: "reports",
        element: <ReportsPage />
      },
      {
        path: "risk-management",
        children: [
          {
            path: "positions",
            element: <Positions />,
          },
          {
            path: "transactions",
            element: <Transactions />,
          },
          {
            path: "wallet-transactions",
            element: <WalletTransactions />,
          },
        ],
      },
      {
        path: "risk-management/positions/:riskId",
        element: <RiskEditPage />,
      },
      {
        path: "risk-management/positions/create",
        element: <RiskCreatePage />,
      },
      {
        path: "analytics",
        element: <AnalyticsPage />,
      },
      {
        path: "blank",
        element: <BlankPage />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "chat",
        element: <ChatPage />,
      },
      {
        path: "internal-chat",
        element: <InternalChatPage />,
      },
      {
        path: "support-chats",
        element: <SupportChatsPage />,
      },
      {
        path: "emails",
        element: <EmailsPage />,
      },
      {
        path: "crypto",
        element: <CryptoPage />,
      },
      {
        path: "ecommerce",
        element: <EcommercePage />,
      },
      {
        path: "file-manager",
        element: <FileManagerPage />,
      },
      {
        path: "kanban",
        element: <KanbanPage />,
      },
      {
        path: "mail",
        element: <MailPage />,
      },
      {
        path: 'calendar',
        element: <CalendarPage />
      }
    ],
  },
];
