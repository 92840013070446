export const productMockedData = [
  {
    header: "CRM",
    description: "Experience the efficiency of a modular framework CRM, tailored for both small scale access and large-scale enterprise operations. Keep your client data secure and within your control, offering peace of mind in an era where data privacy is paramount. Fully customizable to meet your unique business needs, this CRM transforms client management into a seamless, personalized experience.",
    title: "Modular Framework CRM: Customization Meets Efficiency",
    detailDescription: [
      {
        title: "",
        description: "Ready to take the next step? Connect with our account experts today. With their deep knowledge and industry insights, they are poised to guide you through our customized solutions. Whether you have questions, need strategic advice, or are looking for tailored recommendations to fit your unique business needs, our team is here to help. Engage in a conversation with us, and let's explore how we can elevate your business together."
      },
      {
        title: "Secure Client Data Management",
        description: "We understand the paramount importance of data privacy in the modern era. Our CRM is engineered with top-tier security features, ensuring that your client's data remains secure and under your control. This commitment to security offers you and your clients the peace of mind that is essential in today's digital world."
      },
      {
        title: "Tailored to Your Business",
        description: "Every business is unique, and so are its requirements. That's why our CRM is fully customizable. Whether it's adjusting workflows, integrating with your existing systems, or adding specific features, our CRM can be tailored to fit your unique business processes and practices."
      },
      {
        title: "Seamless and Personalized Client Management",
        description: "At the core of our CRM's functionality is the aim to make client management seamless and personalized. From automated data entry to insightful analytics, our CRM turns every interaction with your clients into an opportunity for growth and improved customer satisfaction."
      },
    ],
    features: {
      title: "Features at a Glance",
      items: [
        "Modular structure for scalability",
        "Enhanced data security protocols",
        "Full customization options",
        "Intuitive user interface",
        "Advanced analytics and reporting tools",
        "Seamless integration with existing systems",
        "Automated workflows to boost efficiency",
        "24/7 support and continuous updates",
      ]
    }
  },
  {
    header: "Web Trader",
    description: "Discover the cutting-edge Web Trader, designed with a modern aesthetic to captivate users at first glance. It breaks language barriers, supporting multiple languages for a truly global reach. Experience unparalleled speed and responsiveness, optimized for mobile use, ensuring traders stay connected on the go. This platform is also fully white-labeled, offering a seamless integration with your brand's identity, making it uniquely yours.",
  },
  {
    header: "Comprehensive Bridge Data Reportin",
    description: "Bridge Data Reporting Harness the power of comprehensive trading insights with our data reporting solution. We collect and analyze data from various third-party trading bridges and deliver detailed reports directly to brokers through our app. This service ensures brokers have accurate, timely information at their fingertips to make informed decisions and maintain a competitive edge in the fast-paced financial market.",
  },
  {
    header: "Lead Management",
    description: "Streamline your lead management process with our advanced solution, designed to validate leads and rigorously check for inaccurate or bad data. This system enhances your marketing efforts by providing robust tools to manage affiliate access effectively, ensuring optimal collaboration and control. Its user-friendly integration capabilities make it a breeze to incorporate into your existing workflows, simplifying lead management without sacrificing efficiency.",
  },
  {
    header: "Crypto Wallets",
    description: "Revolutionize your financial services with our Crypto Wallet solution, where each client of our customers receives a uniquely generated wallet. This system streamlines the deposit process, ensuring that funds are securely transferred directly to the owner's wallet. All keys are meticulously stored on the client's server, reinforcing the wallet's reliability and security. This Crypto Wallet system is not only robust but also designed with the highest standards of security in mind, guaranteeing peace of mind for both owners and users.",
  },
  {
    header: "Client Dashboard",
    description: "Upgrade client engagement with our fully customizable Client Dashboard. Designed for complete personalization, it aligns with your unique branding and color scheme. Clients can easily upload KYC documents, with flexible customization to meet diverse needs. The dashboard also functions as an interactive hub for posting updates, engaging in support chats, and highlighting key announcements or leaderboards. More than a dashboard, it's an integral tool for enhancing client interaction.",
  },
  {
    header: "Server Proxy: Secure & Private",
    description: "Enhance your online security with our Server Proxy solution. It provides a dashboard to manage a proxy server, safeguarding your real server IP and guarding against DDoS attacks for reliable online activity. Features include comprehensive reporting and the option to pay with cryptocurrency for added anonymity. This Server Proxy is a formidable defense for your digital operations.",
  },
  {
    header: "Custom Financial Software Development",
    description: "Transform your financial services with our Custom Development service, specializing in creating tailor-made websites, mobile apps, and applications for the financial sector. Our expert team ensures your unique requirements are met with innovative, user-friendly digital solutions. This service is dedicated to elevating your financial business through bespoke software that not only meets but exceeds expectations.",
  },
]