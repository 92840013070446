import { useMemo, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Badge from '@mui/material/Badge';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Link from "@mui/material/Link";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, IconButton, Tooltip } from "@mui/material";
import { format, isYesterday, differenceInDays, differenceInYears, startOfDay } from "date-fns";
import { getSystemMessage } from "src/utils/system-message";
import { styled } from '@mui/material';
import { getAPIUrl } from "src/config";

export const MiniChatMessage = (props) => {
  const {
    authorAvatar,
    authorName,
    body,
    contentType,
    createdAt,
    isClient,
    systemInfo,
    active,
    ...other
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const systemMessage = getSystemMessage(systemInfo, body);

  const textWithLinks = useMemo(() => {
    const linkRegex = /(\b(https?|ftp|file):\/\/(?:[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]|calendly\.com))/ig;
    const link = systemMessage?.match(linkRegex);
    const parts = systemMessage?.split(link);
    const prefix = parts ? parts[0]?.split("<")[0] : '';
    const suffix = parts ? parts[1]?.split(">.")[1] : '';
    if (link) {
      return {
        link, prefix, suffix
      }
    } else {
      return null;
    }
  }, [systemMessage])

  const isDateYesterday = useMemo(() => (
    createdAt ? isYesterday(startOfDay(new Date(createdAt))) : isYesterday(startOfDay(new Date()))
  ), [createdAt]);

  const timeFormat = useMemo(() => {
    const diffInDays = createdAt ? differenceInDays(startOfDay(new Date()), startOfDay(new Date(createdAt))) : 0;
    const diffInYears = createdAt ? differenceInYears(startOfDay(new Date()), startOfDay(new Date(createdAt))) : 0;
    if (diffInDays <= 1) {
      return "h:mm a";
    }
    if (diffInDays > 1 && diffInDays <= 7) {
      return "EEE h:mm a"
    }
    if (diffInDays > 7 && diffInYears < 1) {
      return "MMM d h:mm a"
    }
    if (diffInYears >= 1) {
      return "YYY MMM dd"
    }
  }, [createdAt]);

  const ThreeDot = styled(Box)(() => ({
    float: 'left',
    width: '8px',
    height: '8px',
    margin: '0 4px',
    background: '#8d8c91',
    borderRadius: '50%',
    opacity: '1',
    animation: 'loadingFade 1s infinite',
    '@keyframes loadingFade': {
      '0%': {
        opacity: '0',
      },
      '50%': {
        opacity: '0.8',
      },
      '100%': {
        opacity: '0',
      },
    },
  }
  ));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: isClient ? "flex-end" : "flex-start",
        }}
        {...other}
      >
        <Stack
          alignItems="flex-start"
          direction={isClient ? "row-reverse" : "row"}
          spacing={2}
          sx={{
            maxWidth: 500,
            ml: isClient ? "auto" : 0,
            mr: !isClient ? "auto" : 0,
          }}
        >
          {!isClient && <Tooltip title={authorName}>
            {authorName !== 'System' ?
              <Badge
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom'
                }}
                color={active ? "success" : 'warning'}
                variant="dot"
              >
                <Avatar
                  src={authorAvatar ? authorAvatar?.includes('http') ? authorAvatar : `${getAPIUrl()}/${authorAvatar}` : ""}
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                />
              </Badge> :
              <Avatar
                src={authorAvatar ? authorAvatar?.includes('http') ? authorAvatar : `${getAPIUrl()}/${authorAvatar}` : ""}
                sx={{
                  height: 32,
                  width: 32,
                }}
              />
            }
          </Tooltip>}
          <Box sx={{ flexGrow: 1 }}>
            <Stack
              alignItems='center'
              gap={1}
              direction={isClient ? "row-reverse" : "row"}
              sx={{ pb: '2px' }}>
              {true && <Link
                color="inherit"
                sx={{ cursor: "pointer" }}
                variant="subtitle2"
              >
                {authorName}
              </Link>}
              <Typography
                color="text.secondary"
                noWrap
                variant="caption">
                {isDateYesterday ? 'Yesterday' : ''} {createdAt ? format(new Date(createdAt), timeFormat) : format(new Date(), timeFormat)}
              </Typography>
            </Stack>
            <Card
              sx={{
                backgroundColor:
                  isClient ? "primary.main" : contentType === "system" ? "background.paper" : (contentType === "ellipsis" ? "background.paper" : "primary.main"),
                color:
                  isClient ? "primary.contrastText" : (contentType === "system" ? "text.primary" : "primary.contrastText"),
                px: 2,
                py: 1,
                maxWidth: 'sm',
              }}
            >
              {contentType === "system" && (textWithLinks ?
                <>
                  <Typography>
                    {textWithLinks.prefix}
                  </Typography>
                  <Button
                    onClick={() => setOpenModal(true)}
                    sx={{ p: 0 }}>{textWithLinks.link}</Button>
                  <Typography>
                    {textWithLinks.suffix}
                  </Typography>
                </> :
                <Typography color="inherit"
                  variant="body1">
                  {systemMessage}
                </Typography>
              )}
              {contentType === "ellipsis" && (
                <Box sx={{ py: "4px" }}>
                  <ThreeDot sx={{
                    animationDelay: '0s',
                  }}></ThreeDot>
                  <ThreeDot sx={{
                    animationDelay: '0.2s',
                  }}></ThreeDot>
                  <ThreeDot sx={{
                    animationDelay: '0.4s',
                  }}></ThreeDot>
                </Box>
              )}
              {contentType === "text" && (
                <Stack
                  direction='row'
                  gap={1}>
                  <Typography color="inherit"
                    variant="body1">
                    {body}
                  </Typography>
                </Stack>
              )}
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: isClient ? "flex-end" : "flex-start",
                mt: 1,
                px: 2,
              }}
            >
            </Box>
          </Box>
        </Stack>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          bgcolor: 'white',
          overflow: "hidden",
          border: 1,
          borderColor: 'background.paper',
        }}>
          <>
            <Stack
              direction="row"
              justifyContent='end'>
              <IconButton
                onClick={() => setOpenModal(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Box sx={{ flexGrow: 1, height: 1 }}>
              <iframe
                title="Example Iframe"
                src={textWithLinks?.link ?? ''}
                width="100%"
                height="100%"
                style={{ border: 0 }}
              />
            </Box>
          </>
        </Stack>
      </Modal>
    </>
  );
};
