import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { TempCustomerListTable } from "src/sections/landing/customers/customer-list-table";
import { customerMockedList } from "src/utils/constant/mock-data";
import { usePageView } from "src/hooks/use-page-view";
import { useSelection } from "src/hooks/use-selection";

const Page = () => {
  usePageView();

  const clientIds = customerMockedList?.map((item) => item?.id);
  const customersSelection = useSelection(clientIds ?? []);

  return (
    <>
      <Seo title="Customers" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xxl">
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Customers</Typography>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={2}>
                <Button
                  startIcon={
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Add
                </Button>
              </Stack>
            </Stack>
            <Card>
              <TempCustomerListTable
                onDeselectAll={customersSelection.handleDeselectAll}
                onDeselectOne={customersSelection.handleDeselectOne}
                onDeselectPage={customersSelection.handleDeSelectPage}
                onSelectAll={customersSelection.handleSelectAll}
                onSelectOne={customersSelection.handleSelectOne}
                onSelectPage={customersSelection.handleSelectPage}
                selectAll={customersSelection.selectAll}
                selected={customersSelection.selected}
              />
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;
